<template>
  <v-responsive>
    <SubMenuBigdataMobile v-if="$vuetify.breakpoint.smAndDown"></SubMenuBigdataMobile>
    <v-container :fluid="$vuetify.breakpoint.lgAndDown" style="max-width:1440px;"> 
      <v-row>
        <v-col cols="12" sm="3" flat tile v-if="$vuetify.breakpoint.mdAndUp">
          <v-container class="pa-0">
            <v-card class="d-flex justify-space-between" flat tile>
              <v-card class="pa-0 pl-6" flat tile>
                <h2
                  class="
                    bx_pagetitle
                    float-left
                    mt-5
                    mb-3
                    blue--text
                    text--darken-4
                  "
                >
                  {{ breadcrumbs[1].text }}
                </h2>
              </v-card>
            </v-card>
          </v-container>
          <v-divider></v-divider>


<SubMenuBigdata></SubMenuBigdata>
          
        </v-col>
        <v-col cols="12" sm="12" md="9" :class="$vuetify.breakpoint.smAndDown? 'mt-0 pt-0':''">
          <v-container class="pa-0" v-if="$vuetify.breakpoint.mdAndUp">
            <v-card class="d-flex justify-space-between" flat tile>
              <v-card class="pa-0" flat tile>
                <h2 class="bx_pagetitle float-left mt-5 mb-3">
                  {{ breadcrumbs[2].text }}
                </h2>
              </v-card>
              <v-card class="pa-0" flat tile v-if="$vuetify.breakpoint.mdAndUp">
                <v-breadcrumbs :items="breadcrumbs" class="float-right mt-2">
                  <template v-slot:divider>
                    <v-icon>mdi-chevron-right</v-icon>
                  </template>
                </v-breadcrumbs>
              </v-card>
            </v-card>
          <v-divider></v-divider>
          </v-container>

          <v-sheet min-height="58vh">
            <!--  -->

            <v-img
              alt="Vuetify Logo"
              class="shrink mr-2 ml-2 mt-5"
              contain
              src="@/assets/tmp_2021_goldsystem.png"
              full-width
            />

            <div class="mx-10">
              <div class="text-center mt-10">
                <v-btn
                  color="blue darken-3 white--text"
                  large
                  class="text-subtitle-1 font-weight-bold"
                  @click="createLottoNums(20)"
                >
                  골드 시스템 분석번호 20수 즉시 받기
                </v-btn>
              </div>

              <v-row v-for="(items, i) in lottoNumbers" :key="i" class="mt-10">
                <v-col
                  v-for="(num, i2) in items"
                  :key="i2"
                  cols="2"
                  class="text-center"
                >
                  <v-btn
                    class="mx-2"
                    fab
                    small
                    :color="
                      num > 40
                        ? '#b0d840'
                        : num > 30
                        ? '#aaa'
                        : num > 20
                        ? '#ff7272'
                        : num < 10
                        ? '#69c8f2'
                        : '#fbc400'
                    "
                  >
                    <v-icon
                      class="white--text body-1 font-weight-bold"
                      style="text-shadow: 0px 0px 3px #00000090"
                    >
                      {{ num }}
                    </v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </div>
          </v-sheet>
        </v-col>
      </v-row>
    </v-container>
  </v-responsive>
</template>

<script>
//import HelloWorld from "../components/HelloWorld";

export default {
  name: "Library",

  components: {
    //HelloWorld,
  },
  data: () => ({
    breadcrumbs: [
      { text: "홈" },
      { text: "빅데이터 추출번호" },
      { text: "알고리즘 분석조합" },
      //{ text: "당첨 통계", icon: "mdi-menu-right" },
    ],
    selectedItem: -1,
    admins: [
      ["Management", "mdi-account-multiple-outline"],
      ["Settings", "mdi-cog-outline"],
    ],
    lottoNumbers: null,
  }),

  methods: {
    createLottoNums() {
      this.$axios
        .get("//lotto7777.whynot.so/lottoapp/?mode=create&count=20")
        .then((res) => {
          console.log(res);

          if (res.status === 200) {
            //this.paging = res.data.paging;
            //this.no = this.paging.totalCount - ((this.paging.page-1) * this.paging.ipp);

            //this.classes = res.data;
            this.lottoNumbers = res.data;
          } else {
            alert("실행중 실패했습니다.\n다시 이용해 주세요.");
          }

          if (this.firstLoad) this.firstLoad = false;
          this.loading = false;
        })
        .then((err) => {
          console.log(err);

          this.loading = false;
        });
    },
  },
};
</script>
<style></style>
